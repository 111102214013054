import React, { useEffect, useRef, useState } from 'react'
import { objectOf, any, func } from 'prop-types'
import { Button, DialogActions, DialogContent, Stack, DialogTitle, Typography } from '@mui/material'
import { setOrderFinancePlanInfo } from '@helpers/checkout/payment-section/rtg-finance'
import { getFinancePlans, getSynchronyLink, getLocalStoreCartId } from '@helpers/finance'
// import { fetchCreditPostback } from '@services/credit'
import { fetchClientToken } from '@services/digital-buy'
import ErrorMessage from '@shared/error-message'
import SynchronyLogo from '@shared/svgs/synchrony-logo'
import DigitalBuy from './digital-buy'
import FinancePlan from './finance-plan'
import { ApplyText, DialogDivider, FinancingSvgWrapper, StyledApplyButton } from './styles'

const dbuyUnavailableMsg = 'Synchrony service is currently unavailable, please try again later.'

const RoomsToGoCredit = ({ closeRtgCreditModal, hideAlertModal, hideRtgCreditModal, order, showAlertModal }) => {
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [dbuyToken, setDbuyToken] = useState()
  // const [postbackRefId, setPostbackRefId] = useState(null)
  const financePlan = useRef()
  const financePlan2 = useRef()

  const financePlans = (getFinancePlans(true, 0, true) ?? [])?.filter(
    plan => plan?.financeCode && plan?.financeCode !== 'GENESIS',
  )
  const currentCode = order?.financePlan?.code ?? ''

  const handleCancel = () => {
    if (errorMsg) setErrorMsg('')
    closeRtgCreditModal()
  }

  const getToken = async () => {
    let data
    try {
      data = await fetchClientToken(!!getLocalStoreCartId())
    } catch (error) {
      console.error('Error fetching Synchrony token >>> ', error?.message)
      hideRtgCreditModal(false)
      setErrorMsg()
    }
    // console.log({ data })
    setDbuyToken(data?.clientToken ?? null)
  }

  const resetToken = async () => {
    setDbuyToken(null)
    getToken()
  }

  const setParentDialogCssDisplayState = state => {
    const dialogContainer = document.getElementsByClassName('MuiDialog-root rooms-to-go-credit-container')?.[0]
    if (typeof dialogContainer?.style === 'object') {
      dialogContainer.style.display = state
    }
  }

  useEffect(() => {
    getToken()
  }, []) // eslint-disable-line

  useEffect(() => {
    if (financePlans[0] && (!currentCode || currentCode === 'GENESIS')) {
      const currentPlan = { ...(order?.financePlan ?? {}) }
      setOrderFinancePlanInfo({
        ...currentPlan, // do this to preserve any existing eQuick details
        code: financePlans[0]?.financeCode,
        hasPayments: financePlans[0]?.downPaymentRequired,
      })
    }

    // fetchCreditPostback().then(data => setPostbackRefId(data?.postbackRefId)) // TODO - is this necessary since its value is never used?
  }, []) // eslint-disable-line

  return (
    <>
      <DialogTitle id="payment-modal-synchrony__title" component="div" style={{ padding: '24px 24px 0 24px' }}>
        <Typography variant="h2" sx={{ fontSize: 19, fontWeight: 600, textTransform: 'capitalize' }}>
          Select Financing Plan
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ pt: 0 }}>
        <FinancingSvgWrapper variant="body1">
          Rooms To Go Credit Provided by &nbsp;
          <SynchronyLogo />
        </FinancingSvgWrapper>

        <FinancePlan
          currentCode={currentCode}
          financePlanRef={financePlan}
          financePlan2Ref={financePlan2}
          plans={financePlans}
        />
        {errorMsg && <ErrorMessage customMessage={{ message: errorMsg, id: 'finance-error' }} />}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '0 24px 24px 24px' }}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1.5} sx={{ width: '100%' }}>
          <Button fullWidth variant="outlined" style={{ maxWidth: '45%' }} onClick={!loading ? handleCancel : null}>
            Cancel
          </Button>

          <DigitalBuy
            closeRtgCreditModal={closeRtgCreditModal}
            dbuyToken={dbuyToken}
            hasError={!!errorMsg || !dbuyToken}
            hideAlertModal={hideAlertModal}
            hideRtgCreditModal={hideRtgCreditModal}
            order={order}
            orderFinancePlanCode={order?.financePlan?.code ?? ''}
            resetToken={resetToken}
            setErrorMsg={() => setErrorMsg(dbuyUnavailableMsg)}
            showAlertModal={showAlertModal}
          />
        </Stack>
      </DialogActions>
      <DialogDivider />
      <DialogActions sx={{ padding: '24px' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          sx={{ borderRadius: '2px', padding: '10px', backgroundColor: 'rgba(0, 83, 160, 0.04)', width: '100%' }}
        >
          <ApplyText>Don't have a Rooms To Go Credit Card?</ApplyText>
          <StyledApplyButton
            asButton
            url={`${getSynchronyLink()}&sitecode=rgawel1d2`}
            category="credit-card"
            action="apply-now"
            label="cart"
            title="Rooms To Go Credit Options"
            handleOpen={() => setParentDialogCssDisplayState('none')}
            handleClose={() => setParentDialogCssDisplayState('block')}
          >
            Apply Now
          </StyledApplyButton>
        </Stack>
      </DialogActions>
    </>
  )
}

RoomsToGoCredit.propTypes = {
  closeRtgCreditModal: func,
  hideAlertModal: func,
  hideRtgCreditModal: func,
  order: objectOf(any).isRequired,
  showAlertModal: func,
}

export default RoomsToGoCredit
